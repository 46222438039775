import React from 'react';

import { Stack } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

// eslint-disable-next-line import/no-cycle
import { usePageIndex } from '../../helpers/hooks';
import CardCowListIdentity from '../Cards/CardCowListIdentity';
import CardVideoListIdentity from '../Cards/CardVideoListIdentity';

const styles = makeStyles((theme) => ({
  root: {
    height: '100%',
    padding: theme.spacing(2),
  },
  containerPadding: {
    flexGrow: 'true',
    flex: '1',
    height: '100%',
    marginRight: theme.spacing(2),
    '&:last-child': { marginRight: theme.spacing(0) },
  },
}));

export function IdentityCorrection(props: { pageIndex: number }) {
  const classes = styles();
  const { pageIndex } = props;
  const [currentCow, setCurrentCow] = React.useState<number | undefined>();
  const [currentCollection, setCurrentCollection] = React.useState<number | undefined>();

  usePageIndex(pageIndex);

  return (
    <Stack className={classes.root} direction="row">
      <CardCowListIdentity currentCow={currentCow} setCurrentCow={setCurrentCow} />

      {currentCow !== undefined && (
        <CardVideoListIdentity
          currentCow={currentCow}
          currentCollection={currentCollection}
          setCurrentCollection={setCurrentCollection}
        />
      )}
    </Stack>
  );
}

export default IdentityCorrection;
