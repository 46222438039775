import React from 'react';

import IconCross from '@mui/icons-material/Cancel';
import IconCheck from '@mui/icons-material/CheckCircle';
import UnsnoozeIcon from '@mui/icons-material/HighlightOff';
import SnoozeIcon from '@mui/icons-material/MedicalServices';
import IconCircle from '@mui/icons-material/RadioButtonUnchecked';
import ReportIcon from '@mui/icons-material/Report';
import { Button } from '@mui/material';
import { red } from '@mui/material/colors';
import {
  GridActionsCellItem,
  GridCellParams,
  GridColDef,
  GridRowParams,
  GridValueFormatterParams,
  GridValueGetterParams,
} from '@mui/x-data-grid';

import { Snooze } from '../../api/cow-snooze';
import { checkIdentityAccuracy } from '../Data/OmnieyeAWS';
import { ageString } from '../Data/dataFormatting';
import { APIConfirmedIdentity, collectionState, cowState } from '../Data/dataInterfaces';
import LameStatus from '../common/LameStatus';

const localeDateTimeOptions: Intl.DateTimeFormatOptions = {
  month: 'short',
  day: '2-digit',
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit',
  hour12: false,
};

const localeTimeOptions: Intl.DateTimeFormatOptions = {
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit',
  hour12: false,
};

const localeDateOptions: Intl.DateTimeFormatOptions = {
  month: 'short',
  day: '2-digit',
};

const formatScore = (params: GridValueFormatterParams) =>
  params.value === undefined ? '-' : (params.value as number).toFixed(2);

const formatDateTime = (params: GridValueFormatterParams) => {
  if (params.value === null || params.value === undefined) {
    return '?';
  }

  const d = new Date(params.value as number);

  if (d.valueOf() - new Date().valueOf() > 10 * 365 * 24 * 60 * 60 * 1000) {
    return '-';
  }

  return d.toLocaleString('en', localeDateTimeOptions);
};

const formatDate = (params: GridValueFormatterParams) =>
  params.value === null || params.value === undefined
    ? '?'
    : new Date(params.value as number).toLocaleString('en', localeDateOptions);

const formatTime = (params: GridValueFormatterParams) =>
  params.value === null || params.value === undefined
    ? '?'
    : new Date(params.value as number).toLocaleString('en', localeTimeOptions);

const whitelistedFilterFields = ['eid', 'breed', 'age'];
const getColumns =
  (allColumns: GridColDef[]) =>
  (visibleFields: string[], hiddenFields?: string[]): GridColDef[] =>
    allColumns.map((col: GridColDef) => ({
      ...col,
      hide: !visibleFields.includes(col.field) || hiddenFields?.includes(col.field),
      filterable:
        (visibleFields.includes(col.field) || whitelistedFilterFields.includes(col.field)) &&
        !hiddenFields?.includes(col.field),
    }));

export const cowColumns = getColumns([
  {
    field: 'current_status',
    type: 'number',
    headerName: 'Status',
    width: 100,
    valueGetter: (params: GridValueGetterParams) => (params.row as cowState).last_score,
    renderCell: (params: GridCellParams) => <LameStatus score={params.row.last_score} />,
  },
  {
    field: 'eid',
    type: 'string',
    headerName: 'Electronic ID',
    flex: 1,
  },
  {
    field: 'tag',
    type: 'number',
    headerName: 'Tag',
    flex: 1,
    valueGetter: (params: GridValueGetterParams) =>
      // eslint-disable-next-line no-nested-ternary
      params.row.tag !== undefined
        ? params.row.tag
        : (params.row as cowState).eid !== undefined
        ? -1 // no eid
        : -2, // eid but no tag
    valueFormatter: (params: GridValueFormatterParams) =>
      // eslint-disable-next-line no-nested-ternary
      params.value === undefined
        ? '?'
        : // eslint-disable-next-line no-nested-ternary
        Number(params.value) === -1
        ? 'No Tag'
        : Number(params.value) === -2
        ? 'No eID'
        : params.value,
  },
  {
    field: 'birthID',
    type: 'string',
    headerName: 'Birth ID',
    flex: 1,
  },
  {
    field: 'last_score',
    type: 'number',
    headerName: 'Score',
    flex: 1,
    valueFormatter: formatScore,
  },
  {
    field: 'last_scored',
    type: 'dateTime',
    headerName: 'Last Seen',
    flex: 2,
    valueFormatter: formatDateTime,
    valueGetter: (params: GridValueGetterParams) => new Date(params.value),
  },
  {
    field: 'totalVideos',
    type: 'number',
    headerName: 'Total Videos',
    flex: 1,
    valueGetter: (params: GridValueGetterParams) => params.row.totalCollections,
  },
  {
    field: 'unconfirmed_collections',
    type: 'number',
    headerName: 'Unconfirmed Videos',
    flex: 1,
  },
  {
    field: 'snoozed',
    type: 'dateTime',
    headerName: 'Time Observed',
    flex: 2,
    valueGetter: (params: GridValueGetterParams) => (params.value as Snooze)?.snoozeTime,
    valueFormatter: formatDateTime,
  },
  {
    field: 'snoozeExpiry',
    type: 'dateTime',
    headerName: 'Observed Until',
    flex: 2,
    valueGetter: (params: GridValueGetterParams) => (params.row.snoozed as Snooze)?.snoozeExpiry,
    valueFormatter: formatDateTime,
  },
  {
    field: 'breed',
    type: 'string',
    headerName: 'Breed',
    flex: 1,
  },
  {
    field: 'dob',
    type: 'dateTime',
    headerName: 'Date of Birth',
    flex: 1,
    valueGetter: ({ value }: GridValueGetterParams) => new Date(value),
    valueFormatter: (params: GridValueFormatterParams) =>
      new Date(params.value as number).toLocaleString('en', localeDateOptions),
  },
  {
    field: 'age',
    type: 'string',
    headerName: 'Age',
    flex: 1,
    valueGetter: (params: GridValueGetterParams) => ageString(params.row.dob),
  },
]);

export const collectionColumns = getColumns([
  {
    field: 'status',
    headerName: 'Status',
    width: 100,
    valueGetter: (params: GridValueGetterParams) => (params.row as collectionState).score,
    renderCell: (params: GridCellParams) => <LameStatus score={params.value} />,
  },
  {
    field: 'score',
    type: 'number',
    headerName: 'Score',
    valueFormatter: formatScore,
    flex: 1,
  },
  {
    field: 'uuid',
    type: 'string',
    headerName: 'Video',
    valueFormatter: (params: GridValueFormatterParams) => (params.value as string).substring(0, 8),
    flex: 1,
  },
  {
    field: 'tag',
    type: 'number',
    headerName: 'Tag',
    valueGetter: (params: GridValueGetterParams) =>
      // eslint-disable-next-line no-nested-ternary
      params.row.tag !== undefined
        ? params.row.tag
        : (params.row as collectionState).eid !== undefined
        ? -1 // no eid
        : -2, // eid but no tag
    valueFormatter: (params: GridValueFormatterParams) =>
      // eslint-disable-next-line no-nested-ternary
      params.value === undefined
        ? '?'
        : // eslint-disable-next-line no-nested-ternary
        Number(params.value) === -1
        ? 'No Tag'
        : Number(params.value) === -2
        ? 'No eID'
        : params.value,
  },
  {
    field: 'gateTime',
    type: 'number',
    headerName: 'Gate Time',
    valueFormatter: formatDateTime,
    flex: 1,
  },
  {
    field: 'date',
    type: 'dateTime',
    headerName: 'Date',
    flex: 1,
    valueFormatter: formatDate,
  },
  {
    field: 'dateTime',
    type: 'dateTime',
    headerName: 'Date',
    flex: 1,
    valueGetter: (params: GridValueGetterParams) => (params.row as collectionState).date,
    valueFormatter: formatDateTime,
  },
  {
    field: 'time',
    type: 'dateTime',
    headerName: 'Time',
    flex: 1,
    valueGetter: (params: GridValueGetterParams) => (params.row as collectionState).date,
    valueFormatter: formatTime,
  },
]);

export const cowActions = (selectCowId: (s: number) => void) => ({
  field: 'actions',
  type: 'actions',
  width: 1,
  // headerName: 'Snooze',
  getActions: (params: GridRowParams) => [
    <GridActionsCellItem
      key={`action-snooze-${(params.row as cowState).eid}`}
      icon={<SnoozeIcon sx={{ bgcolor: 'white', borderRadius: 3 }} color="primary" />}
      onClick={() => {
        const cow = params.row as cowState;

        if (cow !== undefined) {
          selectCowId(cow.id);
        }
      }}
      label="Snooze"
    />,
  ],
});

export const unsnoozeCowActions = (unsnoozeAction: (cowState: cowState) => void) => ({
  field: 'unsnooze',
  type: 'actions',
  width: 1,
  // headerName: 'Snooze',
  getActions: (params: GridRowParams) => [
    <GridActionsCellItem
      key={`action-snooze-${(params.row as cowState).eid}`}
      icon={<UnsnoozeIcon sx={{ bgcolor: 'white', borderRadius: 3, color: red[500] }} />}
      onClick={() => {
        const cow = params.row as cowState;

        if (cow !== undefined) unsnoozeAction(cow);
      }}
      label="unsnooze"
    />,
  ],
});

export const collectionActionReportIssue = (showReport: (collection_id: number) => void) => ({
  field: 'actions',
  type: 'actions',
  width: 1,
  headerName: 'x',
  getActions: (params: GridRowParams) => [
    <GridActionsCellItem
      key={`action-id-${(params.row as collectionState).uuid}`}
      icon={<ReportIcon sx={{ bgcolor: 'white', borderRadius: 3 }} color="primary" />}
      onClick={() => {
        showReport((params.row as collectionState).id);
      }}
      label="Report Issue"
    />,
  ],
});

const confirmationIcon = (eid: string | undefined, state: APIConfirmedIdentity | undefined) => {
  const accurate = checkIdentityAccuracy(eid, state);

  if (accurate === true) return <IconCheck sx={{ bgcolor: 'white', borderRadius: 3 }} color="success" />;
  if (accurate === false) return <IconCross sx={{ bgcolor: 'white', borderRadius: 3 }} color="error" />;

  return <IconCircle sx={{ bgcolor: 'white', borderRadius: 3 }} color="primary" />;
};

const clickCollection = (
  correction: string,
  params: GridRowParams,
  selectCollection: (collection: collectionState, correction: string) => void
) => {
  const coll = params.row as collectionState;

  if (coll !== undefined) {
    selectCollection(coll, correction);
  }
};

export const collectionActionCowId = (
  eid: string | undefined,
  selectCollection: (collection: collectionState, correction: string) => void
) => ({
  field: 'actions',
  type: 'actions',
  headerName: 'ID',
  width: 1,
  getActions: (params: GridRowParams) => [
    <GridActionsCellItem
      key={`action-id-${(params.row as collectionState).uuid}`}
      icon={
        <Button
          onClick={() => {
            const accurate = checkIdentityAccuracy(eid, (params.row as collectionState).confirmedIdentity);

            if (accurate === true) {
              clickCollection('removeEid', params, selectCollection);
              clickCollection('addWrongEid', params, selectCollection);
            } else if (accurate === false) {
              clickCollection('removeWrongEid', params, selectCollection);
            } else {
              clickCollection('setEid', params, selectCollection);
            }
          }}
        >
          {confirmationIcon(eid, (params.row as collectionState).confirmedIdentity)}
        </Button>
      }
      label="Cow ID"
    />,
  ],
});
